/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* --------------------------------------------------------- */
html,
body,
#root,
.full-height {
  /* min-height: 100%;
  min-width: 100%;
  width: 100%; */
  /* height: 100%; */
  margin: 0px !important;
  font-size: 1.11em;
  line-height: 1.11em;
}

h1,
h2,
h3,
h4,
h5,
input,
form,
button,
p {
  font-family: "Sen", sans-serif;
  font-family: "Rubik", sans-serif !important;
  font-weight: 300;
  color: black !important;
  font-size: 1.11em;
}

i {
  font-style: italic;
}

div {
  font-family: "Sen", sans-serif;
}

h1 {
  font-size: 2em;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 5px;
}

h2 {
  font-size: 1.6em;
  line-height: 1em;
  font-weight: 600;
}
h3 {
  font-size: 1.3em;
  font-weight: 600;
}
h4 {
  font-size: 1.1em;
  font-weight: 600;
}
h5 {
  font-size: 1em;
  font-weight: 600;
}

em {
  font-size: 2em;
  font-style: normal;
}

p {
  font-size: 1em;
}

b {
  font-weight: 600;
}

form {
  font-size: 1em;
}

input {
  font-family: "Caveat", cursive !important;
  font-size: 1.3em !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  /* color: white; */
  font-style: italic;
}
::-moz-placeholder {
  /* Firefox 19+ */
  /* color: white; */
  font-style: italic;
}
:-ms-input-placeholder {
  /* IE 10+ */
  /* color: white; */
  font-style: italic;
}
:-moz-placeholder {
  /* Firefox 18- */
  /* color: white; */
  font-style: italic;
}

.organic-arrow {
  color: #ff0da5;
}

fieldset {
  text-align: center !important;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.tilt {
  -ms-transform: rotate(270);
  -moz-transform: rotate(270);
  -webkit-transform: rotate(270);
  -o-transform: rotate(270);
  transform: rotate(270) !important;
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
